import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import _toConsumableArray from "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\@babel\\runtime\\helpers\\esm\\toConsumableArray.js";
import _defineProperty from "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\@babel\\runtime\\helpers\\esm\\defineProperty.js";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/tag/style";
import _Tag from "vant/es/tag";
import "vant/es/card/style";
import _Card from "vant/es/card";
import "vant/es/pull-refresh/style";
import _PullRefresh from "vant/es/pull-refresh";
import "vant/es/list/style";
import _List from "vant/es/list";
import "vant/es/button/style";
import _Button from "vant/es/button";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// eslint-disable-next-line no-unused-vars
import { getAppointList, changeAppoint } from '@/api/store';
export default {
  name: 'Home',
  components: (_components = {}, _defineProperty(_components, _Button.name, _Button), _defineProperty(_components, _List.name, _List), _defineProperty(_components, _PullRefresh.name, _PullRefresh), _defineProperty(_components, _Card.name, _Card), _defineProperty(_components, _Tag.name, _Tag), _defineProperty(_components, _Toast.name, _Toast), _components),
  data: function data() {
    return {
      list: [],
      loading: false,
      // 列表loading
      finished: false,
      // 是否完成加载
      refreshing: false,
      filterData: {
        limit: 10,
        page: 0
      },
      recordDialogVisible: false,
      appointmentData: {}
    };
  },
  created: function created() {},
  methods: {
    getList: function getList(refresh) {
      var _this = this;

      var _self = this;

      getAppointList(this.filterData).then(function (res) {
        if (res.error.code === 0) {
          // 加载状态结束
          _self.loading = false; // 数据全部加载完成

          if (refresh === 'refresh') {
            _this.list = res.data.list;
          } else {
            var tempList = [].concat(_toConsumableArray(_this.list), _toConsumableArray(res.data.list));
            _this.list = tempList;
          }

          if (_this.list.length >= res.data.totalCount) {
            _self.finished = true;
          } else {
            _self.finished = false;
          }
        }
      });
    },
    onLoad: function onLoad() {
      this.filterData.page += 1;
      this.getList();
    },
    onRefresh: function onRefresh() {
      this.refreshing = false;
      this.finished = true; // 防止下拉刷新发送了两次请求的问题

      this.filterData.page = 1;
      this.getList('refresh');
      this.list = [];
    },
    // 返回
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    },
    // 已完成的预约-点击确认查看记录，并确认完成
    showRecordDialog: function showRecordDialog(data) {
      this.appointmentData = data;
      this.recordDialogVisible = true;
    },
    // 确认 状态： 1已预定（已锁） 2已付款（进行中） 3已完成 4已结束 5 (取消/自动取消)已解锁  6-待分账 7-分账成功 8-分账失败
    confirmAppoint: function confirmAppoint() {
      var _this2 = this;

      var saveData = {
        id: this.appointmentData.id,
        status: 4
      };
      changeAppoint(saveData).then(function (res) {
        if (res.error.code === 0) {
          _Toast('操作成功');

          _this2.getList('refresh');
        }
      });
    },
    // 取消
    cancelAppoint: function cancelAppoint(data) {
      var _this3 = this;

      _Dialog.confirm({
        title: '',
        message: '确定取消预约吗？',
        showCancelButton: true
      }).then(function () {
        var saveData = {
          id: data.id,
          status: 5
        };
        changeAppoint(saveData).then(function (res) {
          if (res.error.code === 0) {
            _Toast('操作成功');

            _this3.getList('refresh');
          }
        });
      });
    }
  }
};