var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "预约信息",
          "left-text": "",
          "right-text": "",
          "left-arrow": "",
        },
        on: { "click-left": _vm.onClickLeft },
      }),
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function ($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing",
          },
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: _vm.finished,
                "finished-text":
                  _vm.list.length === 0 ? "暂无数据" : "没有更多了",
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            _vm._l(_vm.list, function (item) {
              return _c("van-cell", { key: item.id }, [
                _c("div", { attrs: { slot: "default" }, slot: "default" }, [
                  _c(
                    "div",
                    { staticClass: "goods-container" },
                    [
                      _c("van-image", {
                        staticClass: "van-col imageBorder",
                        attrs: {
                          width: "80px",
                          height: "80px",
                          src: item.image,
                        },
                      }),
                      _c(
                        "van-row",
                        { staticClass: "content-box" },
                        [
                          _c("div", { staticClass: "content-name" }, [
                            _vm._v(_vm._s(item.store_name)),
                          ]),
                          _c("div", { staticClass: "content-des" }, [
                            _vm._v(
                              " 姓名：" +
                                _vm._s(item.patient) +
                                " " +
                                _vm._s(
                                  " 共" +
                                    item.total_appointment_num +
                                    "次第" +
                                    item.cur_times +
                                    "次"
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "content-des" }, [
                            _vm._v(
                              " 预约：" +
                                _vm._s(item.date) +
                                " " +
                                _vm._s(item.time_name) +
                                " "
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              class: [4, 5].includes(item.status)
                                ? "content-des"
                                : "",
                            },
                            [_vm._v(" 状态: " + _vm._s(item.status_text) + " ")]
                          ),
                          item.status === 3
                            ? _c(
                                "van-tag",
                                {
                                  staticClass: "bottom_right_icon",
                                  attrs: {
                                    round: "",
                                    type: "primary",
                                    size: "large",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showRecordDialog(item)
                                    },
                                  },
                                },
                                [_vm._v("确认")]
                              )
                            : _vm._e(),
                          item.status === 1 &&
                          !item.appointment_day &&
                          !(_vm.repeat_num > 0)
                            ? _c(
                                "van-tag",
                                {
                                  staticClass: "bottom_right_icon",
                                  attrs: {
                                    round: "",
                                    type: "warning",
                                    size: "large",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelAppoint(item)
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "van-dialog",
        {
          attrs: {
            title: "请确认养眼记录",
            "show-cancel-button": true,
            "show-confirm-button": true,
            "close-on-click-overlay": "",
          },
          on: { confirm: _vm.confirmAppoint },
          model: {
            value: _vm.recordDialogVisible,
            callback: function ($$v) {
              _vm.recordDialogVisible = $$v
            },
            expression: "recordDialogVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-container" },
            [
              _c(
                "van-row",
                { staticClass: "dialog-van-row" },
                [
                  _c("van-col", { attrs: { span: "12" } }, [
                    _vm._v(
                      "疗前左视: " + _vm._s(_vm.appointmentData.bef_eye_left)
                    ),
                  ]),
                  _c("van-col", { attrs: { span: "12" } }, [
                    _vm._v(
                      "疗前右视: " + _vm._s(_vm.appointmentData.bef_eye_right)
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "van-row",
                { staticClass: "dialog-van-row" },
                [
                  _c("van-col", { attrs: { span: "24" } }, [
                    _vm._v(
                      "疗前备注: " + _vm._s(_vm.appointmentData.bef_eye_remark)
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "van-row",
                { staticClass: "dialog-van-row" },
                [
                  _c("van-col", { attrs: { span: "12" } }, [
                    _vm._v(
                      "疗后左视: " + _vm._s(_vm.appointmentData.aft_eye_left)
                    ),
                  ]),
                  _c("van-col", { attrs: { span: "12" } }, [
                    _vm._v(
                      "疗后右视: " + _vm._s(_vm.appointmentData.aft_eye_right)
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "van-row",
                { staticClass: "dialog-van-row" },
                [
                  _c("van-col", { attrs: { span: "24" } }, [
                    _vm._v(
                      "疗后备注: " + _vm._s(_vm.appointmentData.aft_eye_remark)
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }